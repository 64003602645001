
<template>
<div class="signup">
  <v-card
    class="mx-auto"
    style="max-width: 500px;"
    v-if="signupShow"
  >
    <v-toolbar
      color="deep-gray accent-4"
      cards
      dark
      flat
    >
      <v-btn @click="signupShow=false" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-card-title class="text-h6 font-weight-regular">
        회원 가입
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form
      ref="form"
      v-model="form"
      class="pa-4 "
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="credentials.username"
              required
              :rules="[rules.length(4)]"
              filled
              color="deep-purple"
              counter="20"
              label="사용자ID"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="credentials.last_name"
              required
              filled
              color="deep-purple"
              counter="2"
              label="성"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="credentials.first_name"
              required
              filled
              color="deep-purple"
              counter="10"
              label="이름"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="credentials.email"
              required
              filled
              color="deep-purple"
              counter="30"
              label="이메일"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="credentials.password"
              :rules="[rules.password, rules.length(8)]"
              filled
              color="deep-purple"
              counter="8"
              label="비밀번호"
              type="password"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="credentials.passwordConfirmation"
              :rules="[rules.password, rules.length(8)]"
              filled
              color="deep-purple"
              counter="8"
              label="비밀번호 확인"
              type="password"
            ></v-text-field>
          </v-col>
        <v-checkbox
          v-model="agreement"
          :rules="[rules.required]"
          color="deep-purple"
        >
          <template v-slot:label>
            I agree to the&nbsp;
            <a
              href="#"
              @click.stop.prevent="dialog = true"
            >Terms of Service</a>
            &nbsp;and&nbsp;
            <a
              href="#"
              @click.stop.prevent="dialog = true"
            >Privacy Policy</a>*
          </template>
        </v-checkbox>
        </v-row>
      </v-container>
    </v-form>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        text
        @click="$refs.form.reset()"
      >
        Clear
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!form"
        :loading="isLoading"
        class="white--text"
        color="deep-purple accent-4"
        depressed
        @click="signup"
      >
        등록
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="dialog"
      absolute
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-3">
          Legal
        </v-card-title>
        <v-card-text>
          Tony Quant에서 제공하는 정보는 신뢰할 만한 자료 및 정보로부터 얻어진 것이나 그 정확성이나 완전성을 보장 할 수 없으며, 시간이 경과함에 따라 변경될 수 있습니다. 
          모든 콘텐츠에 대한 저작권은 Tony Quant에 있으며 사전 허가없이 이를 무단으로 사용하거나, 데이터 베이스화 할 경우 민형사상 책임을 물을 수 있습니다.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="agreement = false, dialog = false"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="deep-purple accent-4"
            @click="agreement = true, dialog = false"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>      
    </v-dialog>
  </v-card>

   <!-- 알림 메세지 -->
  <v-snackbar
    v-model="snackbar1"
    :vertical="vertical"
    :color="snackColor" 
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        닫기
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    v-model="snackbar2"
    :vertical="vertical"
    :color="snackColor"    
    timeout="-1"      
  >
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        to="/hello"
      >
        닫기
      </v-btn>
    </template>
  </v-snackbar>
 </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';

// const SERVER_URL = process.env.VUE_APP_SERVER_URL

export default {
  name: 'Signup',
  data: function () {
    return {
      credentials : {
        username : null,
        last_name: null,
        first_name: null,
        email: null,
        password : null,
        passwordConfirmation : null,
      },
      signupShow: true,
      agreement: false,
      dialog: false,
      form: false,
      isLoading: false,
      rules: {
        length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
        password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
          '비밀번호는 반드시 숫자, 문자, 특수문자 조합으로 8자리 이상이어야 합니다.',
        required: v => !!v || 'This field is required',
      },
      snackbar1: false,
      snackbar2: false,
      vertical: true,
      snackColor: null,
      snackText: null,
    }
  },

  computed: {
    ...mapState(['debug', 'isLogin']) 
  },

  created() {
    if (this.isLogin) this.$router.push("/")
    // else this.signup();
  },

  methods: {
    signup() {
      var vm = this;
      axios.post('/accounts/signup/', this.credentials) 
                .then(function(res) {
                    if (res.data['error']) {
                      vm.snackbar1 = true;
                      vm.snackColor = "error";
                      vm.snackText = "비밀번호가 일치하지 않습니다.";
                    } else {
                      vm.snackbar2 = true;
                      vm.snackColor = "info";
                      vm.snackText = "가입을 축하드립니다. 관리자 승인 후 사용가능합니다. 관리자에게 연락해주세요.";
                    }                    
                })
                .catch(function (err) {
                    console.log("POST ERR", err);
                });
    }
  }
}
</script>

<style>
.signup{
   padding: 10px 10px;
   margin-top: 10px;
}
</style>